
import { Options, Vue } from "vue-class-component";
import { mapGetters, mapMutations } from "vuex";
import { MealId } from "../interfaces/Meal";
import { BasketFullInfoItem, BasketItem, BasketItemId } from "../interfaces/Basket";
import { AdditionId, AdditionsInMeal } from "../interfaces/Addition";
import EmptyBasket from "../components/EmptyBasket.vue";

@Options({
  components: { EmptyBasket },
  computed: {
    ...mapGetters("basket", ["getBasketFullInfo", "getBasketItemCost", "basketCost"]),
    ...mapGetters("additions", ["getAdditionPrice", "getAdditionsCost"]),
  },
  methods: {
    ...mapMutations(["changeDisplayedMealId"]),
    ...mapMutations("basket", ["updateBasketItem", "deleteBasketItem"]),
  },
})
export default class Basket extends Vue {
  getBasketFullInfo!: Array<BasketFullInfoItem>;

  getBasketItemCost!: (item: BasketItem) => number;

  basketCost!: number;

  getAdditionPrice!: (additionId: AdditionId) => number | false;

  getAdditionsCost!: (data: AdditionsInMeal) => number;

  changeDisplayedMealId!: (mealId: MealId) => void;

  updateBasketItem!: (item: BasketItem) => void;

  deleteBasketItem!: (index: BasketItemId) => void;

  changeAdditionCount(item: BasketFullInfoItem, index: string | number, count: number): void {
    const changedItemData = { ...item };
    changedItemData.additions[index] = count;
    this.updateBasketItem(changedItemData);
  }

  deleteAddition(item: BasketFullInfoItem, index: string | number): void {
    const basketItem = { ...item };
    delete basketItem.additions[index];
    this.updateBasketItem(basketItem);
  }

  changeMealCount(item: BasketFullInfoItem, count: number): void {
    const basketItem = { ...item };
    basketItem.count = count;
    this.updateBasketItem(basketItem);
  }

  deleteItemInBasket(index: BasketItemId): void {
    this.deleteBasketItem(index);
  }
}
